import React from 'react';
import '../styles/components_style/footer.scss';

const Footer = () => {

    const handleFacebookIconClick = () => {
        window.location.href = 'https://www.youtube.com';
    }

    const handleInstaIconClick = () => {
        window.location.href = 'https://www.instagram.com/cocobino.co/';
    }

    return (
        <section className="footer">
        <div className="blocFooter">
            <div className="blocHaut">
                <div className="logoFooter">COCOBINO</div>
            </div>
            <div className="blocMilieu">
                <div className="blocF-footer">
                    <ul className="menuFooter">
                        <li className="liFooter"><a href='/'><p className='navLink'>Home</p></a></li>
                        <li className="liFooter"><a href="/about"><p className='navLink'>A propos</p></a></li>
                        <li className="liFooter"><a href="/contact"><p className='navLink'>Contact</p></a></li>
                        <li className="liFooter"><a href="/boutique"><p className='navLink'>Voyages</p></a></li>
                        <li className="liFooter"><a href="/articles"><p className='navLink'>Article</p></a></li>
                        <li className="liFooter"><a href="/"><p className='navLink'>Conditions de Vente</p></a></li>
                    </ul>
                </div>
            </div>
            <div className="blocBas">
                <div className="logoInsta">
                    <i className="fa-brands fa-instagram" onClick={handleInstaIconClick}></i>
                </div>
                <div className="logoFb"><i className="fa-brands fa-facebook-f" onClick={handleFacebookIconClick}></i></div>
            </div>
        </div>
    </section>
    );
};

export default Footer;