import React from 'react';
import emailicon from '../styles/assets/icons/error_icon.svg';
import '../styles/components_style/alertError.scss'

const AlertError = () => {
    return (
        <div>
            <div className='alert'>
                <img src={emailicon} alt="email icon" id='emailIcon'/>
                <div className='alert_P'>
                    <p>Veuillez remplir les champs obligatoires</p>
                </div>
            </div>
        </div>
    );
};

export default AlertError;