import React, { useState } from "react";
import SliderForTravelPage from "./SliderForTravelPage";
import AdventageBubble from "./AdventageBubble";
import '../../styles/components_style/travelInformation.scss';

export function TravelInformation({
    hotelTitle,
    testImagesArrays,
    testRoomAdvantage,
    testHotelDescription,
    hotelLocation,
    dates
}) {

    const [readDescription, setReadDescription] = useState(false);

    const isClickDescription = () => {
        if (readDescription === true) {
            setReadDescription(false)
        }
        if (readDescription === false) {
            setReadDescription(true)
        }

    }

    return <div className='travelInformation'>
        <div className='travelInformation_bigScreenResp'>

            <SliderForTravelPage imageList={testImagesArrays} />

            <div className='travelInformation_bigScreenResp_titleAndAdventage'>
                <div className="travelInformation_titleWrapper">
                    <h1>{hotelTitle}</h1>
                </div>
                <AdventageBubble adventageList={testRoomAdvantage} />
            </div>

        </div>
        <div className='travelInformation_description'>
            <h3>Description de l'hébergement</h3>
            {readDescription === false ? <div className='travelInformation_description_textBasic'>
                <p>{testHotelDescription}</p>
            </div> : <div className='travelInformation_description_textExpand'>
                <p>{testHotelDescription}</p>
            </div>}

        </div>
        <p onClick={isClickDescription}>
            <strong>
                {readDescription === false ? 'tout lire' : 'fermer la description'}
            </strong>
        </p>
        <div className='travelInformation_details'>
            <h3>Détails du séjour</h3>
            <div className='travelInformation_details_location'>
                <div className='travelInformation_details_location_iconContainer'>
                    <img className='travelInformation_details_location_iconContainer_img' src="https://res.cloudinary.com/dpistjmej/image/upload/v1721852254/location-svgrepo-com_1_xzjk8i.svg" alt="" />
                </div>
                <strong><p>{hotelLocation}</p></strong>
            </div>
        </div>
        <div className='travelInformation_details_dateContainer'>
            <div>
                <p>date arrivé</p>
                <p className='travelInformation_details_dateContainer_date'><strong>{dates[0]}</strong></p>
            </div>
            <div>
                <p>date dépard</p>
                <p className='travelInformation_details_dateContainer_date'><strong>{dates[1]}</strong></p>
            </div>
        </div>
    </div>;
}
