import React from 'react';
import img1 from '../../../styles/assets/images/articles/domaine sonia/web-cocobino.jpg';
import img2 from '../../../styles/assets/images/articles/domaine sonia/sonia2.jpg';
import img3 from '../../../styles/assets/images/articles/domaine sonia/sonia3.jpg';
import img4 from '../../../styles/assets/images/articles/domaine sonia/sonia4.jpg';
import img5 from '../../../styles/assets/images/articles/domaine sonia/sonia5.jpg';
import img6 from '../../../styles/assets/images/articles/interieur du dome Mare.jpg';
import PageVenteTemplate from './PageVenteTemplate';

const PageVenteArticleCorse = () => {
    return (
        <PageVenteTemplate 
            im1={img1}  
            im2={img2}
            im3={img3}
            im4={img4}
            im5={img5}
            im6={img6}
            title={'Corse'}
        />
    );
};

export default PageVenteArticleCorse;