import React, { useState } from 'react';
import Button from '@mui/material/Button';
import '../../styles/components_style/shoppingCart.scss'
import CartItem from './CartItem';

const ShoppingCart = ({ ItemList, removeFromCart, dates, goToReservationPage, totalPrice, totalItemsInCart}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    //const [shoppingCart, setShoppingCart] = useState([]);

    const displayModal = () => {
        if (isModalOpen === false) {
            setIsModalOpen(true);
        } else {
            setIsModalOpen(false);
        }
    }

    return (
        <>
            <div className='shoppingList' onClick={displayModal}>
                <div className='shoppingList_textBox'>
                    {isModalOpen === false ? (
                        <img className='shoppingList_svgIcon' src="https://res.cloudinary.com/dpistjmej/image/upload/v1720952312/shopping-cart-1-svgrepo-com_xphrwu.svg" alt="" />
                    ) : (
                        <p>clickez ici pour fermer le panier</p>
                    )}
                </div>
                <div className='shoppingList_priceBox'>
                    <p>{totalPrice} €</p>
                </div>
                <div className='itemCountBubble'><p>{totalItemsInCart}</p></div>
            </div>
            {isModalOpen ? (
                <div className="containerShoppingCart">
                    <div className="containerShoppingCart_margin">
                        <div className="containerShoppingCart_titleAndText">
                            <h3>Votre voyage</h3>
                            <p>Voici une petite description qu’on mettra pour expliquer aux utilisateur</p>
                        </div>
                        <div className="containerShoppingCart_date-range">
                            <span className="containerShoppingCart_date-range_spanLeft">De <strong>{dates[0]}</strong></span>
                            <span>{"à "}<strong>{dates[1]}</strong></span>
                        </div>
                        {ItemList.map((item, key) => {
                            return (
                                <CartItem
                                    sourceImg={item.imgUrl[0]}
                                    title={item.title}
                                    nombreDePersonnes={item.numberOfPersons}
                                    nombreChildren={item.numberOfChildren}
                                    prix={item.totalPrice}
                                    key={key}
                                    index={key}
                                    removeFunction={removeFromCart}
                                    isActivity={item.isActivity}
                                    isOnReservationPage={false}
                                />
                            )
                        })}


                        <div className="containerShoppingCart_total">
                            <p>Total :</p>
                            <p className="containerShoppingCart_total_amount">{totalPrice} €</p>
                        </div>
                        <Button variant="contained" size='small' style={{
                            backgroundColor: 'black',
                            marginTop: '10px',
                            width: '100%',
                            textTransform: 'lowercase',
                            height: '30px',
                            boxShadow: "none"
                        }}
                            onClick={()=>goToReservationPage()}
                        > <p>Réserver le voyage</p></Button>

                    </div>

                </div>
            ):null}
        </>
    );
};

export default ShoppingCart;