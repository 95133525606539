import React from 'react';
import '../../styles/components_style/CTA.css';
import ButtonClassic from '../ButtonClassic';

const CTA = () => {
    return (
        <div className='CTA'>
            <div className='CTA__Title'>
                <h2>Préparz votre voyage dès maintenant et visitez ces cafés avec Cocobino !</h2>
            </div>
            <div className='CTA__propositionValuer'>
                <p>
                Préparez facilement votre voyage au Luxembourg avec Cocobino. Un voyage sur mesure qui vous correspond. Cliquez sur <strong>réservez</strong> pour commencer !
                </p>
            </div>
            <div className='CTA__creationUrgence'>
                <p>
                Ne manquez pas notre offre spéciale valable uniquement pour les <strong>48 prochaines 
                heures !</strong>
                </p>
            </div>
            <div className='CTA__imgAndButtonContainer'>
                <div className='CTA__ButtonAndAction'>
                    <ButtonClassic name='Réservez' number={2} newPage={"/boutique"}/>
                    <p id="p-margin"><strong>Seulement maintenant garantie remboursement aprés 15 jours</strong></p>
                </div>
            </div>
        </div>
    );
}

export default CTA;
