import React from 'react';
import '../../styles/pages_style/Article.css';
import ArticleHeader from '../../components/component_Articles/ArticleHeader';
import ArticleContent from '../../components/component_Articles/ArticleContent';
import CTA from '../../components/component_Articles/CTA';
import ArticleEnd from '../../components/component_Articles/ArticleEnd';
//import ArticleRecommendation from '../../components/component_Articles/ArticleRecommendation';
import Header2 from "../../components/Header2";
import Footer from '../../components/Footer';
import img from "../../styles/assets/images/articles-image-de-café-luxembourg.webp";
import img1 from '../../styles/assets/images/articles/image-café-Konrad-Caf-Bar.webp';
import img2 from '../../styles/assets/images/articles/image-café-Bloom.webp';
import img3 from '../../styles/assets/images/articles/image-café-café-froid-Luxembourg.webp';
import img4 from '../../styles/assets/images/articles/image-café-golden-Bean.webp';
import img5 from '../../styles/assets/images/articles/down-town.webp';
import img6 from '../../styles/assets/images/articles/image-café-Fellows.webp';

const Article = () => {
    document.title = "Top 6 des meilleurs cafés au Luxembourg";
    return (
        <div className='article'>
            <div className='article__container'>
                <Header2 />
                <ArticleHeader 
                    articleTitle={"Les 6 meilleurs cafés luxembourgeois"}
                    mainImg={img}
                    imgDescription={"Belle image"}
                    articleDescription={"Plongez dans l'atmosphère chaleureuse et délicieuse du Luxembourg avec notre sélection des 6 meilleurs cafés. Découvrez des adresses uniques proposant des saveurs exquises, des spécialités locales et des ambiances captivantes. Laissez-vous transporter par des cafés authentiques où le charme luxembourgeois rencontre l'art du café. Un voyage gustatif qui éveillera tous vos sens."}
                />
                <ArticleContent 
                    contentTitle={"1. Konrad Café & Bar"}
                    contentImg={img1}
                    contentText={"Découvrez cet endroit cosy avec un bar sous-terrain, offrant une ambiance boisée et une variété de café et de délicieux petits gâteaux de qualité. Vous serez charmé par l'atmosphère intime et le service attentionné. Un véritable havre de paix pour les amateurs de café."}
                />
                <ArticleContent 
                    contentTitle={"2. Bloom"}
                    contentImg={img2}
                    contentText={"Idéalement situé en plein centre-ville, ce café accueillant est parfait pour des petits déjeuners matinaux. L'ambiance y est conviviale et l'accueil sympathique. Vous pourrez déguster des boissons chaudes savoureuses dans un cadre moderne et lumineux."}
                />
                <ArticleContent 
                    contentTitle={"3. Café froid"}
                    contentImg={img3}
                    contentText={"Ce restaurant à thème, dans une ambiance de livre ancien et une lumière tamisée, propose un café de qualité. L'intérieur est superbement décoré pour une expérience super cool. Laissez-vous séduire par le charme unique de cet établissement, où le café et l'atmosphère se marient à merveille."}
                />
                <CTA />
                <ArticleContent 
                    contentTitle={"4. Golden Bean Café"}
                    contentImg={img4}
                    contentText={"Populaire et décontracté, ce café est idéal pour se détendre entre amis. Vous y trouverez des cafés du monde entier et une décoration superbe. Profitez d'une atmosphère détendue et d'une excellente sélection de cafés provenant des quatre coins du globe."}
                />
                <ArticleContent 
                    contentTitle={"5. DownTown"}
                    contentImg={img5}
                    contentText={"Ce café discret en plein centre-ville vous charmera par sa décoration principalement en brun et bois. L'accueil chaleureux et leur spécialité, le cappuccino parfaitement réalisé, sont très appréciés des Luxembourgeois. Plongez dans une ambiance cosy et savourez chaque gorgée d'un café préparé avec soin."}
                />
                <ArticleContent 
                    contentTitle={"6. Fellows"}
                    contentImg={img6}
                    contentText={"Situé au cœur du Luxembourg, ce café est très apprécié des jeunes. Le personnel est sympathique et ils proposent également des cookies et des donuts. Son ambiance est particulièrement agréable en hiver, et les cafés glacés en été valent également le détour. Laissez-vous séduire par l'énergie et l'atmosphère branchée de ce café animé."}
                />
                <ArticleEnd />
                <CTA />
                <Footer />
            </div>
        </div>
    );
}

export default Article;
