import React from 'react';
import '../../styles/components_style/ArticleContent.css';

const ArticleContent = ({contentTitle, contentImg, contentText}) => {
    return (
        <div className='content'>
            <div className='content__subTitleContainer'>
                <h2>{contentTitle}</h2>
            </div>
            <div className='content__imgContainer'>
                <img src={contentImg} alt="" id='imgArticle'/>
            </div>
            <div className='content__description'>
                <p>
                    {contentText}
                </p>
            </div>
        </div>
    );
}

export default ArticleContent;
