import React from 'react';
import ButtonClassic from '../components/ButtonClassic';
//import pinterestLogo from '../styles/assets/icons/pinterest-svgrepo-com.svg';

const HomePage = () => {
    
    const handleFacebookIconClick = () => {
        window.location.href = 'https://www.pinterest.com/cocobinoc/';
    };

    const handleInstaIconClick = () => {
        window.location.href = 'https://www.instagram.com/cocobino.co/';
    }

    return (
        <section className='container1'>
            <div className="blocHaut1-1">
                <div className="blocTexte1-1">
                    <div className="titreC1-1">
                        <h1 id='mainTitle'>Cocobino, un voyage au Luxembourg qui vous correspond</h1>
                    </div>
                    <div className="presentationC1-1">
                        <p>Découvrez le Luxembourg avec Cocobino ! Des week-ends 
                            uniques et des campings de qualité. Réservez vos 
                            activités et restaurants en quelques clics. Profitez 
                            pleinement de votre voyage sans souci, vos enfants sont 
                            pris en charge. Succombez à l'appel du Luxembourg, laissez 
                            l'aventure commencer avec Cocobino ! <strong>Réservez maintenant !</strong></p>
                    </div>
                    <div className="blocBouton1-1">
                        <ButtonClassic 
                            name={"Explorer nos voyages"}
                            number={1}
                            newPage={'/boutique'}
                        />
                        <ButtonClassic
                            name={"Qui sommes nous"}
                            number={2}
                            newPage={'/about'}
                        />
                    </div>
                </div>
                <div className="blocReseau1-1">
                        <div className="imageFB" onClick={handleFacebookIconClick}>
                            <i className="fa-brands fa-facebook-f">
                            </i> 
                        </div>
                        <div className="imageInstagram" onClick={handleInstaIconClick}>
                            <i className="fa-brands fa-instagram"></i>

                        </div>
                    </div>
            </div>
            <div className="blocBas1-1">
                    <div className="blocReseauBas1-1">
                    </div>
                        <div className="blocSwap1-1">
                            <div className="boutonSwap"></div>
                            <div className="boutonSwap"></div>
                            <div className="boutonSwap"></div>
                            <div className="boutonSwap"></div>
                        </div>
                    <div className="blocReseauBas1-1">
                        <div className="imageFB">
                            <i className="fa-brands fa-facebook-f"></i> 
                        </div>
                        <div className="imageInstagram"><i className="fa-brands fa-instagram"></i></div>
                    </div>
                </div>
        </section>
    );
};

export default HomePage;