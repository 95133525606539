import React from 'react';
import '../styles/pages_style/ArticlePage.css';
import img from '../styles/assets/images/articles-luxembourg-paysages1.webp';
import ArticleBox from '../components/ArticleBox';
import img2 from "../styles/assets/images/articles-image-de-café-luxembourg.webp";
import imgVoyageResponsable from '../styles/assets/images/articles/image-un-couple-voyage-responsable-en-europe-dans-une-tente.jpg';
import Header2 from "../components/Header2";
import Footer from '../components/Footer';
import ScrollToTop from "../components/ScrollTop";

const ArticlePage = () => {
    document.title = "Lisez nos article sur le Luxembourg - lisez et visitez";
    return (
        
        <div className='ArticlePage'>
            <Header2 />
            <ScrollToTop />
            <div className='ArticlePage__imgContainer'>
                <img src={img} alt="" id='img' />
                <div className='ArticlePage__titleContainer'>
                    <div className='ArticlePage__titleContainer_title'>
                        <h1>Découvrez le Luxembourg à traver nos articles</h1>
                    </div>
                    <div className='ArticlePage__titleContainer_text'>
                        <p>
                        Découvrez le Luxembourg à travers nos articles captivants ! Plongez dans la richesse culturelle, explorez les trésors cachés et laissez-vous inspirer par nos récits de voyage. Préparez-vous à être transporté au cœur de l'aventure luxembourgeoise. Lisez, rêvez et embarquez dans une expérience unique avec Cocobino.
                        </p>
                    </div>
                </div>
            </div>
            <div className='ArticlePage__subTitle'>
                <div className='ArticlePage__subTitle_titleContainer'>
                    <h2>Voyagez en lisant nos artices</h2>
                </div>
            </div>
            <div className='ArticlePage__articleSection'>
                <div className='ArticlePage__articleSection__articleContainer'>
                    <ArticleBox 
                        imgDeLaBox={img2}
                        description={"Découvrez les cafés luxembourgeois : les 6 meilleurs cafés à ne pas manquer !"}
                        titreDeLaBox={'Les 6 meilleurs cafés luxembourgeois'}
                        lien={"/article"}
                    />
                    <ArticleBox 
                        imgDeLaBox={imgVoyageResponsable}
                        description={"Explorez des destinations écologiques d'exception en europe avec Cocobino ..."}
                        titreDeLaBox={'Voyage Responsable Inoubliable ici en europe'}
                        lien={"/articles/article-voyageResponsable"}
                    />
                </div>
            </div>
            <Footer/>
        </div>
    );
}

/*

*/

export default ArticlePage;
