import React from 'react';
import ButtonClassic from '../components/ButtonClassic';


const addPack = (item) => {
    <form action="pack.php" method="post">
        <input type="hidden" name="number" value="2" />
        <input type="submit" value="Explorer" className="boutonStandard" name="Explorer"/>
    </form>
}

const HomePageSecond = () => {
    return (
    <section className="container2">
        <div className="container2-1">
            <div className="bloc2-1">
                <div className="titreC2-1"><h2>Visiter le meilleur du Luxembourg</h2></div>
                <div className="descriptionC2-1"><p>Qui part en vacance ?</p></div>
            </div>

            <div className="bloc2-2">

                <div className="blocStandard1">
                    <div className="BST"><h3>Pack Aventure</h3></div>
                    <div className="BSD"><p>Un séjour plus mouvementé idéal pour un groupe d’amis ou famille à la recherche d’un voyage aux sensation fortes.</p></div>
                    <ButtonClassic 
                        name={"Réserver"}
                        number={2}
                        onClick={addPack(1)}
                        newPage={"/boutique"}
                        className='test'
                    />
                </div>

                <div className="blocStandard2">
                    <div className="BST"><h3>Pack Family</h3></div>
                    <div className="BSD"><p>Choisissez la formule Family pour profiter d’un voyage convivial et chaleureux idéal pour un séjour en  famille</p></div>
                    <ButtonClassic 
                        name={"Réserver"}
                        number={2}
                        onClick={addPack(2)}
                        newPage={"/boutique"}
                    />
                </div>

                <div className="blocStandard3">
                    <div className="BST"><h3>Pack Coco</h3></div>
                    <div className="BSD"><p>Vous êtes à la recherche d’un séjour calme et luxueux, ne cherchez pas plus loin, la formule Coco est faite pour vous.</p></div>
                    <ButtonClassic 
                        name={"Réserver"}
                        number={2}
                        onClick={addPack(3)}
                        newPage={"/boutique"}
                    />
                </div>

            </div>
        </div>
    </section>
    );
};

export default HomePageSecond;