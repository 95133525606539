import React from 'react';
import '../../styles/components_style/ArticleHeader.css';

const ArticleHeader = ({articleTitle,mainImg,imgDescription,articleDescription}) => {
    return (
        <div className='ArticleHeader'>
            <div className='ArticleHeader__titleContainer'>
                <h1>{articleTitle}</h1>
            </div>
            <div className='ArticleHeader__imgContainer'>
                <img src={mainImg} alt={imgDescription} id='img'/>
            </div>
            <div className='ArticleHeader__descriptionContainer'>
                <p>{articleDescription}</p>
            </div>
        </div>
    );
}

export default ArticleHeader;
