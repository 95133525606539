import React, { useState, useMemo, useEffect } from "react";
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import About from "./pages/About";
import Home from "./pages/Home";
import Boutique from "./pages/Boutique";
import Contact from "./pages/Contact";
import ArticlePage from "./pages/ArticlePage";
import Article from "./pages/article/Article";
import ArticleVoyageResponsable from "./pages/article/ArticleVoyageResponsable";
import PageVenteArticleFinlande from "./pages/article/pageVente/PageVenteArticleFinlande";
import PageVenteArticleBelgique from "./pages/article/pageVente/PageVenteArticleBelgique";
import PageVenteArticleCorse from "./pages/article/pageVente/PageVenteArticleCorse";
import PageVenteArticleSuisse from "./pages/article/pageVente/PageVenteArticleSuisse";
import TravelPage from "./pages/sejours/TravelPage";
import ReservationPage from "./pages/ReservationPage";

import Hotel_DB from "./db.json";

/* import db from "./api/db";*/

const App = () => {

  const [dateD, setDateD] = useState(() => localStorage.getItem("dateD") || null);
  const [dateA, setDateA] = useState(() => localStorage.getItem("dateA") || null);

  function getHotel() {
    return JSON.parse(localStorage.getItem("hotelFTP"))  || null;
  }

  const [hotelForTravelPage, setHotel] = useState(getHotel())

  const checkOutDates = () => {
    if (dateD === null && dateA === null) {
      return false
    } else {
      return true;
    }
  }

  const dates = [dateA, dateD];

  const howManyNights = useMemo(() => {
    const start = new Date(dateA);
    const end = new Date(dateD);
    const diffInTime = end.getTime() - start.getTime();
    const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));
    const numberOfNights = diffInDays - 1;
    return numberOfNights;
  }, [dateA, dateD]);


  useEffect(() => {
    if (dateD !== null) {
      localStorage.setItem("dateD", dateD);
    }
    if (dateA !== null) {
      localStorage.setItem("dateA", dateA);
    }

  }, [dateD,dateA]);

  const [travelResumeInLocal, setTravelResumeInLocal] = useState(() => {
    const storedData = localStorage.getItem("travelResume");
    return storedData ? JSON.parse(storedData) : null;
  });

  useEffect(() => {
    if (travelResumeInLocal === null) {
      localStorage.setItem("travelResume", travelResumeInLocal);
    }

  }, [travelResumeInLocal]);

  function setTravelResumeForTravelPage(hotelLocation, dateDepard, dateArrive, itemList, totalPrice, nights) {
    let trvR = {
      "locationHotel": hotelLocation,
      "dateDepard": dateDepard,
      "dateArrive": dateArrive,
      "itemList": itemList,
      "totalPrice": totalPrice,
      "nuits": nights
    }
    localStorage.setItem("travelResume", JSON.stringify(trvR));
    setTravelResumeInLocal(trvR);
  }

  return (
    <BrowserRouter>
      <Routes>
        
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/boutique" element={<Boutique setDateA={setDateA} setDateD={setDateD} checkOutDates={checkOutDates} db={Hotel_DB} setHotel={setHotel}/>} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Home />} />
        <Route path="/articles" element={<ArticlePage />} />
        <Route path="/article" element={<Article />} />
        <Route path="/articles/article-voyageResponsable" element={<ArticleVoyageResponsable />} />
        <Route path="/articles/article-voyageResponsable/pageVenteFinlande" element={<PageVenteArticleFinlande />} />
        <Route path="/articles/article-voyageResponsable/pageVenteBelgique" element={<PageVenteArticleBelgique />} />
        <Route path="/articles/article-voyageResponsable/pageVenteCorse" element={<PageVenteArticleCorse />} />
        <Route path="/articles/article-voyageResponsable/pageVenteSuisse" element={<PageVenteArticleSuisse />} />
        
        <Route path="/boutique/:hotel_title/:hotel_id" element={<TravelPage dates={dates} nights={howManyNights} setTravelResume={setTravelResumeForTravelPage} travelPageHotel={hotelForTravelPage} activity_db={Hotel_DB.activity}/>} />
        
        <Route path="/boutique/:hotel_title/:hotel_id/reservationPage" element={<ReservationPage travelResume={travelResumeInLocal} />} />
        
      </Routes>
    </BrowserRouter>

  )
}

export default App;