import React from 'react';
import '../../styles/components_style/cartItem.scss'

const CartItem = ({ sourceImg, title, nombreDePersonnes, prix, removeFunction, index, isActivity, nombreChildren, isOnReservationPage }) => {
    return (
        <div className="containerShoppingCart_item">
            <div className="containerShoppingCart_item_bouble">
                <div className='containerShoppingCart_item_bouble_imgContainer'>
                    <img className='containerShoppingCart_item_bouble_imgContainer_img' src={sourceImg} alt="Chambre du compté" />
                </div>
                <div className="containerShoppingCart_item_bouble_content">
                    <div className="containerShoppingCart_item_bouble_content_title">
                        <strong><p>{title}</p></strong>
                    </div>
                    <div className='containerShoppingCart_item_bouble_content_text'>
                        {isActivity === true ? (
                            <>
                                <div className='containerShoppingCart_item_bouble_content_text_activity'>
                                <p>adultes : <strong>{nombreDePersonnes}</strong></p>
                                <p>enfants : <strong>{nombreChildren}</strong></p>
                                </div>
                                <p>prix total : <strong>{prix}</strong> €</p>
                            </>
                        ) : (
                            <>
                                <p>Personnes : <strong>{nombreDePersonnes}</strong></p>
                                <p>prix total : <strong>{prix}</strong> €</p>
                            </>                           
                        )}
                    </div>
                </div>
            </div>
            {isOnReservationPage === false ? (

            <button className="containerShoppingCart_item_deleteBtn" style={{ cursor: "pointer" }} onClick={() => { removeFunction(index) }}>
                <img src="https://res.cloudinary.com/dpistjmej/image/upload/v1720999877/delete-2-svgrepo-com_d8wez6.svg" alt="" className='containerShoppingCart_item_deleteBtn_svgIcon' />
            </button>
            ):null}
        </div>
    );
};

export default CartItem;