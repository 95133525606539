import React from 'react';
import Header2 from "../../../components/Header2";
import '../../../styles/pages_style/pageVenteArticle.css';

const PageVenteTemplate = ({title,im1,im2,im3,im4,im5,im6}) => {
    const emailLink = () => {
        window.location.href = 'mailto:cocobinotravelmail@gmail.com'
    }

    const mail = 'cocobinotravelmail@gmail.com';

    return (
        <div className='pageView'>
            <Header2/>
            <div className='pageContentContainer'>
                <div className='titleContainer'>
                    <h1>Rejoignez-nous dans cette aventure <br /> extraordinaire en {title}</h1>
                </div>
                <div className='titleContainer'>
                    <h2>Plus d'images</h2>
                </div>
                <div className='imageSection'>
                    <div className='imageContainer'><img src={im1} alt="" className='img'/></div>
                    <div className='imageContainer'><img src={im2} alt="" className='img'/></div>
                    <div className='imageContainer'><img src={im3} alt="" className='img'/></div>
                    <div className='imageContainer'><img src={im4} alt="" className='img'/></div>
                    <div className='imageContainer'><img src={im5} alt="" className='img'/></div>
                    <div className='imageContainer'><img src={im6} alt="" className='img'/></div>
                </div>
                <div className='textSection'>
                    <h2>Explorez le Monde avec Cocobino : Vivez l'Exceptionnel</h2>
                    <p className='explainationText'>
                    Vous rêvez d'une escapade luxueuse, confortable, tout en contribuant à l'économie locale? Bienvenue chez Cocobino, votre passerelle vers des voyages d'exception.
                    </p>
                    <h2>Notre Différence : Luxe, Confort et Responsabilité</h2>
                    <p className='explainationText'>
                    Chez Cocobino, nous changeons la donne. Un voyage responsable ne signifie pas compromis sur le luxe et le confort. Oubliez l'idée préconçue que les voyages responsables sont destinés à une élite, nos offres sont accessibles à tous, car chacun mérite une expérience inoubliable
                    </p>
                    <h2>Offre Exclusive : -20% pour 48h seulement</h2>
                    <p className='explainationText'>
                    En ce moment, nous vous offrons une réduction exceptionnelle de 20% sur toutes nos offres. Mais attention, cette offre est limitée dans le temps. Réservez maintenant pour vivre l'aventure de votre vie à un prix imbattable!
                    </p>
                    <h2>Pourquoi Attendre? Contactez-nous dès Aujourd'hui!</h2>
                    <p className='explainationText'>
                    Ne manquez pas cette opportunité unique de créer des souvenirs inoubliables tout en contribuant positivement aux communautés locales. Contactez-nous dès aujourd'hui pour obtenir un devis personnalisé et commencez votre voyage avec Cocobino!
                    </p>
                    <p>Notre email :</p>
                    <strong><p>{mail}</p></strong>
                    <div className='buttonSpacing'>
                        <button id='buttonPageVente' onClick={emailLink}><p>contactez-nous maintenant et obtenez votre devis</p></button>
                    </div>
                    
                </div>
            </div>

        </div>
    );
};

export default PageVenteTemplate;