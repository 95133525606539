import React from 'react';
import { useNavigate } from 'react-router-dom';


const ButtonClassic = ({ name, number, newPage}) => {

  const navigate = useNavigate();

  const handleClick = () => {
      navigate(newPage);
  };

  return (
        <div>
        <button
            className={`containerButton${number === 1 ? 'Black' : 'White'}`}
            onClick={handleClick}
        >
            {name}
        </button>
        </div>
  );
};

export default ButtonClassic;