import { React, useMemo, useState } from 'react';
import SliderCarroussel from './SliderCarroussel';
import Button from '@mui/material/Button';
import '../../styles/components_style/activityBubble.scss';
import IncrementButtonGroup from './IncrementButtonGroup';
import ButtonSimple from '../ButtonSimple';

const ActivityBubble = ({ addFunction, activityImgUrlArr, activityTitle, activityAdvantageArr, activityPriceAdulte, activityDescription, activityLocation, activityPriceEnfant }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => { setIsModalOpen(true); }
    const closeModal = () => { setIsModalOpen(false); }

    const [readRoomDescription, setReadRoomDescription] = useState(false);

    const displayRoomDescription = () => {
        if (readRoomDescription === false) {
            setReadRoomDescription(true);
        } else {
            setReadRoomDescription(false);
        }
    }

    const [numberOfPersonA, setNumberOfPersonA] = useState(0);
    const [numberOfPersonC, setNumberOfPersonC] = useState(0);

    const totalActivityPrice = useMemo(()=>{
        return (numberOfPersonA * activityPriceAdulte) + (numberOfPersonC * activityPriceEnfant);
    },[numberOfPersonA,activityPriceAdulte,numberOfPersonC,activityPriceEnfant]);


    function handleClickAddToCart() {
        addFunction(numberOfPersonA, activityTitle, totalActivityPrice, activityImgUrlArr, true, numberOfPersonC, activityLocation);
        closeModal();
    }

    return (
        <>
            <div className='activity_containerWrapper'>
                <div className="activity_roomContainer">
                    <div className="activity_roomContainer_elmntCenter">
                        <div className="activity_roomContainer_elmntCenter_imgContainer">
                            <img className='activity_roomContainer_elmntCenter_imgContainer_img' src={activityImgUrlArr[0]} alt="" loading='lazy' />
                        </div>
                        <div className="activity_roomContainer_elmntCenter_reservation">
                            <div className="activity_roomContainer_elmntCenter_reservation_title">
                                <h3 className='roomtitle_activity'>{activityTitle}</h3>
                            </div>

                            <div className="activity_roomContainer_elmntCenter_reservation_calltoActionBtn">
                                <div className="buttonWrapper">
                                    <Button variant="contained" size='small' onClick={openModal} style={{ 'backgroundColor': 'black', 'fontSize': '11px', 'boxShadow': 'none', width: "100%" }}> <p>voir l'activité</p> </Button>
                                </div>
                            </div>
                        </div>
                        <div className='activity_roomContainer_elmntCenter_advantageContainer'>
                            <div className='activity_roomContainer_elmntCenter_advantageContainer_topSetting'>
                                {activityAdvantageArr.map((string, key) => {
                                    return (
                                        <div className='activity_roomContainer_elmntCenter_advantageContainer_elmnt' key={key}>
                                            <p className='activity_roomContainer_elmntCenter_advantageContainer_elmnt_p' key={key}>{string}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {isModalOpen && (
                <div className={`activity_roomContainer_screenWrapper`}>

                    <div className="activity_roomContainer_popupRoom">

                        <div className="buttonContainer">
                            <ButtonSimple label={"fermer"} number={1} onClickFunction={closeModal}/>
                        </div>
                        <div className='imageSliderWrapper'>
                            <SliderCarroussel imageList={activityImgUrlArr} />
                        </div>
                        <div className="activity_popupRoom_titleWrapper">
                            <h3>{activityTitle}</h3>
                        </div>
                        <div className="activity_popupRoom_addresse">
                            <div className='activity_popupRoom_addresse_imgContainer'>
                                <img className='travelInformation_details_location_iconContainer_img' src="https://res.cloudinary.com/dpistjmej/image/upload/v1721852254/location-svgrepo-com_1_xzjk8i.svg" alt=""
                                    style={{
                                        width: "90%",
                                        height: "100%"
                                    }} />
                            </div>
                            <strong><p>{activityLocation}</p></strong>
                        </div>

                        <div style={{marginTop:"10px"}}>
                            <p>- <strong>{activityPriceAdulte} €</strong> / adulte</p>
                            <p>- <strong>{activityPriceEnfant} €</strong> / Enfant</p>
                        </div>
                        <p
                            onClick={displayRoomDescription}
                            style={{ cursor: "pointer", marginTop: "10px" }}
                        > <strong>{readRoomDescription === false ? ('lire la descripion') : ('fermer la description')}</strong>
                        </p>
                        <div className="activity_popupRoom_descriptionContainer">
                            {readRoomDescription && (
                                <p>{activityDescription}</p>
                            )}
                        </div>

                        <div className="roomList_popupRoom_numberOfPersonContainer">
                            <p>nombre d'adultes : </p>
                            <IncrementButtonGroup
                                setResult={setNumberOfPersonA}
                                maxNumberOfPerson={10}
                            />
                        </div>
                        <div className="roomList_popupRoom_numberOfPersonContainer" style={{ marginTop: "20px" }}>
                            <p>nombre d'enfants : </p>
                            <IncrementButtonGroup
                                setResult={setNumberOfPersonC}
                                maxNumberOfPerson={10}
                            />
                        </div>
                        <div className="roomList_popupRoom_priceAndBtnBlock">
                            <div className="roomList_popupRoom_priceAndBtnBlock_priceContainer">
                                <p className='roomList_popupRoom_priceAndBtnBlock_priceContainer_price' style={{ color: 'green' }}>{totalActivityPrice} €</p>
                                <div className='roomList_popupRoom_priceAndBtnBlock_priceContainer_text'>
                                    <p>tarif all inclusive</p>
                                </div>

                            </div>
                            <div className='roomList_popupRoom_priceAndBtnBlock_btnWrapper'>
                                <Button variant="contained" style={{
                                    'backgroundColor': 'black',
                                    'fontSize': '12px',
                                    'width': '90%'
                                }}
                                    onClick={handleClickAddToCart}
                                >Ajouter l'activité</Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ActivityBubble;