import React, { useState } from 'react';
import '../styles/components_style/TravelPlanner.scss';
//, MenuItem, Select 
import { TextField, Button } from '@mui/material';
import { useForm } from "react-hook-form"

const TravelPlanner = ({ setDateA, setDateD }) => {

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm()

    const [dateValidate, setDateValidate] = useState(false)

    const onSubmit = (data) => {
        setDateA(data.arrivalDate);
        setDateD(data.departureDate);
        setDateValidate(true)
    }

    return (
        <div className="navbarTravelPlanner">
            <div className='navbarTravelPlanner__container'>
                

                    {errors.arrivalDate ? (
                        <TextField
                            error
                            label="Date d'arrivé requis !"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            style={{ paddingLeft:"10px"}}
                            {...register("arrivalDate", { required: "ce champ est requis" })}
                        />
                    ) : (
                        <TextField
                            label="Date d'arrivé"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            style={{ paddingLeft:"10px"}}
                            {...register("arrivalDate", { required: "ce champ est requis" })}
                        />
                    )}
                    {errors.departureDate ? (
                        <TextField
                            error
                            label="Date de départ requis !"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            style={{ paddingLeft:"10px"}}
                            {...register("departureDate", { required: "ce champ est requis" })}
                        />
                    ) : (
                        <TextField
                            label="Date de départ"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            style={{ paddingLeft:"10px"}}
                            {...register("departureDate", { required: "ce champ est requis" })}
                        />
                    )}
                

                <div className='navbarTravelPlanner__container__finalInputs'>
                    {dateValidate === true ? (
                        <Button variant="contained" style={{ width: "100%", height: "100%", backgroundColor: "green" }} onClick={handleSubmit(onSubmit)}>
                            Dates ok
                        </Button>
                    ) : (
                        <Button variant="contained" color="primary" style={{ width: "100%", height: "100%" }}
                            onClick={handleSubmit(onSubmit)}
                        >Valider</Button>
                    )}
                </div>
                
            </div>
        </div>
    );
};

export default TravelPlanner;
