import React, { useState, useMemo} from 'react';
import ShoppingCart from '../../components/newComponents/ShoppingCart';
import ActivityBubble from '../../components/newComponents/ActivityBubble';
import Button from '@mui/material/Button';
import RoomBouble from '../../components/newComponents/RoomBouble';
import { TravelInformation } from '../../components/newComponents/TravelInformation';
import "../../styles/pages_style/TravelPage.scss"
import Header2 from '../../components/Header2';
import Footer from '../../components/Footer';
import { useParams, useNavigate } from 'react-router-dom';
import Alert from '../../components/Alert';
import ScrollToTop from '../../components/ScrollTop';

const TravelPage = ({dates, nights, setTravelResume, travelPageHotel, activity_db}) => {

    const { hotel_title, hotel_id } = useParams();
    const [cartItems, setCartItems] = useState([]);
    const navigate = useNavigate();
    const [openAlert, setOpenAlert] = useState(false);

    
    function addToShoppingCart(numberOfPerson, title, price, roomUrlImg,isActivity,nombreEnfants,activityLocation) {        
        if (numberOfPerson === 0) {
            console.log("YOU NEED TO CHOOSE HOW MANY PERSON");
        } else {
            let room = {
                "title": title,
                "numberOfPersons": numberOfPerson,
                "totalPrice": price,
                "imgUrl": roomUrlImg,
                "isActivity":isActivity,
                "numberOfChildren":nombreEnfants,
                "locationActivite":activityLocation
            }
            setCartItems([...cartItems, room]);
        }
    }

    function removeFromCart(index) {
        setCartItems(cartItems.filter((_, i)=> i!== index));
        console.log("remove From cart is called");   
    }

    const totalCartPrice = useMemo(()=>{
        return cartItems.reduce((total, item)=>total + item.totalPrice, 0)
    },[cartItems]);

    const totalItems = useMemo(()=>{
        return cartItems.length;
    },[cartItems]);

    function getReservationPage() {
        if(cartItems.length===0){
            setOpenAlert(true);
        } else {
            setTravelResume(travelPageHotel.location,dates[1],dates[0],cartItems,totalCartPrice,nights);
            navigate(`/boutique/${hotel_title}/${hotel_id}/reservationPage`);
        }
    }

    return (

        <>
            <Header2 />
            <ScrollToTop />
            <Alert message={"votre panier est vide"} openState={openAlert} setOpenFunction={setOpenAlert}/>
            <div className='connexionPage'>
                <ShoppingCart ItemList={cartItems} removeFromCart={removeFromCart} dates={dates} goToReservationPage={getReservationPage} totalPrice={totalCartPrice} totalItemsInCart={totalItems}/>
                <TravelInformation hotelTitle={hotel_title} testImagesArrays={travelPageHotel.imgUrlArray} testHotelDescription={travelPageHotel.description} testRoomAdvantage={travelPageHotel.advantages}
                    hotelLocation={travelPageHotel.location} dates={dates}/>
                <div className='title_headSection'>
                    <h3>Chambres disponibles</h3>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nisi reprehenderit placeat id a laboriosam similique.</p>
                </div>
                <div className='roomList'>
                    {travelPageHotel.rooms.map((room, index) => {
                        return (
                            <RoomBouble
                                addFuction={addToShoppingCart}
                                roomTitle={room.titleRoom}
                                roomImg={room.imgUrlArrayRoom}
                                roomAdvantages={room.advantageRoom}
                                roomPrice={room.price}
                                roomDescription={room.descriptionRoom}
                                roomMaxOfPerson={room.maxOfPerson}
                                key={index}
                                nights={nights}
                            />
                        )
                    })}

                </div>
                <div className='title_headSection'>
                    <h3>Carte cadeau exclusive Cocobino</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit consectetur impedit accusamus, minus at atque quia sint possimus placeat cupiditate molestiae ab vero, delectus fugiat fugit, sed expedita sequi assumenda inventore. Ad quod maxime labore? Sunt, nesciunt itaque! Provident ipsam eveniet aliquid labore aliquam quisquam odit numquam recusandae in delectus?</p>
                </div>

                <div className='exclusiveSection'>
                    <div className="exclusiveSection_cartAdventageContainer">
                        <div className="exclusiveSection_cartAdventageContainer_adventages">
                            <ul className='exclusiveSection_cartAdventageContainer_adventages_list'>
                                <li>service premium partout</li>
                                <li>gagnez du temps en étant servie en premier</li>
                                <li>cadeaux exclusives partout</li>
                                <li>traitement exclusive vip partout</li>
                            </ul>
                        </div>
                    </div>
                    <p style={{ marginTop: '10px' }}>Voici la liste d'activitées auquel vous aurez un traitement vip*</p>
                </div>
                <div className='roomList'>
                    
                    {activity_db.map((activty, key) => {
                        return (
                            <ActivityBubble
                                addFunction={addToShoppingCart}
                                activityTitle={activty.title}
                                activityImgUrlArr={activty.imgUrlArray}
                                activityDescription={activty.description}
                                activityAdvantageArr={activty.advantages}
                                activityLocation={activty.location}
                                activityPriceAdulte={activty.prixAdulte}
                                activityPriceEnfant={activty.prixEnfant}
                                key={key}
                            />
                        )
                    })}
                    
                </div>

                <div className='btnContainerEndOfPage' onClick={()=>getReservationPage()} >
                    <Button variant="contained" style={{ 'backgroundColor': 'green', 'boxShadow': 'none', 'width': '100%', marginTop: "35px" }}>passer à la réservation du voyage</Button>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default TravelPage;