import React from 'react';
import App from './App';
import "./styles/index.scss";
import { hydrate, render } from "react-dom";

const APP =(
  <App />
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}

