import React from 'react';
import '../styles/components_style/_header.css';
import logo from '../styles/assets/logo/logo.svg';

const Header2 = () => {
    return (
        <div>
            <div className="logo-ban">
                <a href="/"><img src={logo} alt="" className="logo-ban__logo" /></a>
            </div>

            <input type="checkbox" id="ham-menu"/>
            <label htmlFor="ham-menu">
                <div className="hide-des">
                    <span className="menu-line"></span>
                    <span className="menu-line"></span>
                    <span className="menu-line"></span>
                    <span className="menu-line"></span>
                    <span className="menu-line"></span>
                    <span className="menu-line"></span>
                </div>
            </label>
            <div className="full-page-green"></div>
            <div className="ham-menu">
                <ul className="centre-text bold-text">
                    <li><a href="/"><p className='bold-text centre-text'>Home</p></a></li>
                    <li><a href="/boutique"><p className='bold-text'>Nos voyages</p></a></li>
                    <li><a href="/contact"><p className='bold-text'>Contact</p></a></li>
                    <li><a href="/about"><p className='bold-text centre-text'>À propos</p></a></li>
                    <li><a href="/articles"><p className='bold-text'>Article</p></a></li>
                </ul>
            </div>
        </div>
    );
};

export default Header2;