import React from 'react';
import { useState, useRef, useEffect } from 'react';
import "../../styles/components_style/sliderCarroussel.css";

const SliderCarroussel = ({ imageList }) => {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);
    const containerRef = useRef(null);

    const nextSlide = () => {
        setCurrentIndex((currentIndex + 1) % imageList.length);
    };

    const prevSlide = () => {
        setCurrentIndex((currentIndex - 1 + imageList.length) % imageList.length);
    };

    const innerContainer = {
        display: 'flex',
        transition: 'transform 0.5s ease',
        height: '100%',
        width: `calc(100%*${imageList.length})`,
        position: 'relative',
        transform: `translateX(-${(currentIndex * 100) / imageList.length}%)`
    }


    useEffect(() => {

        const handleResize = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }

        handleResize();

    }, [])

    return (
        <div className="container" ref={containerRef}>
            <div className="btnContainerWrapper" style={{ width: `${containerWidth}px`}}>
                <div className='btn prev' onClick={prevSlide}><img className='svgIcon' src="https://www.svgrepo.com/show/238535/next.svg" alt="" /></div>
                <div className='btn next' onClick={nextSlide}><img className='svgIcon' src="https://www.svgrepo.com/show/238535/next.svg" alt="" /></div>
            </div>
            <div className='containerInner' style={innerContainer}>
                {imageList.map((img, key) => (
                    <div className='imageContainer' key={key}>
                        <img className='img' src={img} alt="" key={key} loading='lazy' />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SliderCarroussel;
