import { React, useEffect, useState } from 'react';
import '../../styles/components_style/incrementButtonGroup.scss';

const IncrementButtonGroup = ({ setResult, maxNumberOfPerson }) => {

    const [numberOfPerson, setNumberOfPerson] = useState(0);

    function handleAddPerson() {
        if (numberOfPerson < maxNumberOfPerson) {
            setNumberOfPerson(prevNumberOfPerson => prevNumberOfPerson + 1);
            setResult(numberOfPerson);
        }

    }

    function handleDeletePerson() {
        if (numberOfPerson > 0) {
            setNumberOfPerson(prevNumberOfPerson => prevNumberOfPerson - 1);
            setResult(numberOfPerson);
        }
    }

    useEffect(() => {
        setResult(numberOfPerson)
    }, [numberOfPerson, setResult]);

    return (
        <div>
            <div className='activité__numberOfPeople'>
                <button className='activité__numberOfPeople__signContainer'
                    onClick={handleDeletePerson}
                    id='addButton'
                >
                    <p className='activité__numberOfPeople__signContainer_sign'>-</p>
                </button>
                <div>
                    <p>{numberOfPerson}</p>
                </div>
                <button className='activité__numberOfPeople__signContainer'
                    onClick={handleAddPerson}
                    id='deleteButton'
                >
                    <p className='activité__numberOfPeople__signContainer_sign'>+</p>
                </button>
            </div>
        </div>
    );
};

export default IncrementButtonGroup;