import React from 'react';
import Snackbar from "@mui/material/Snackbar";
//import '../styles/components_style/alert.scss'

const Alert = ({message, openState, setOpenFunction}) => {

    /*const [open, setOpen] = useState(false);
    const handleClick = () => {
      setOpen(true); // Ouvrir l'alerte
    };*/
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenFunction(false); // Fermer l'alerte
    };
  

    return (
        <Snackbar
        open={openState}
        autoHideDuration={1100}  // La durée avant que l'alerte ne disparaisse (en millisecondes)
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position de l'alerte
        message={"votre panier est vide"}
      >
        <div style={{height:"auto",width:"auto",backgroundColor:"#fc9d03",padding:"5px",borderRadius:"9px",color:"white"}}>
          <p>{message}</p>
        </div>
      </Snackbar>
    );
};

export default Alert;