import React from 'react';
import '../styles/components_style/ArticleBox.css';

const ArticleBox = ({imgDeLaBox, titreDeLaBox,description,lien}) => {
    return (
    <div className='ArticlePage__articleSection__article'>
        <div className='ArticlePage__articleSection__article__imgContainer'>
            <div className='imgContainer'>
                <img src={imgDeLaBox} alt="" id='img2'/>
            </div>
            <div className='articleContainer'><h4 className='ArticleTitle'>{titreDeLaBox}</h4></div>
        </div>
        <div className='ArticlePage__articleSection__article__textAndButtonContainer'>
            <div className='ArticlePage__articleSection__article__text'>
                <p>
                    {description} <strong><u><a href={lien}>Lire maintenant !</a></u></strong>
                </p>
            </div>
        </div>
    </div>
    );
}

export default ArticleBox;
