import React from 'react';
import '../../styles/components_style/ArticleEnd.css';

const ArticleEnd = () => {
    return (
        <div className='articleEnd'>
            <div className='articleEnd__Title'>
                <h2>Conclusion :</h2>
            </div>
            <div className='articleEnd__toSum'>
                <p>
                Partez à la découverte de ces cafés uniques, savourez des arômes délicieux et imprégnez-vous de l'atmosphère accueillante qui caractérise la scène café luxembourgeoise. Une expérience inoubliable pour les amateurs de café et les amoureux des lieux authentiques.
                </p>
            </div>
        </div>
    );
}

export default ArticleEnd;
