import React from 'react';
import '../styles/pages_style/About.scss';
import Footer from "../components/Footer";
import Header2 from "../components/Header2";
import ScrollToTop from "../components/ScrollTop";
//images
import img from '../styles/assets/images/articles-luxembourg-paysages1.webp';
import natureLuxembourg from '../styles/assets/images/image-luxembourg-nord-camping-luxembourg.webp';
import mudamLuxembourg from '../styles/assets/images/image-luxembourg-Mudam-photo-visiter-Luxembourg.webp';
import villeLuxembourgNuit from '../styles/assets/images/image-ville-du-luxembourg-centre-ville.webp';

import ButtonClassic from '../components/ButtonClassic';

const About = () => {
    document.title = "À propos de Cocobino - Créateurs de Voyages Personnalisés Axés sur l'Expérience";
    return (

        <div>

            <Header2 />
            <ScrollToTop />
            <section className="containerAbout">
                <div className="containerAbout-1">
                    <div className="BlocVideAbout"></div>
                    <div className="BlocHautAbout">
                        <div className="B-TitreAbout">
                            <div className="TitreAbout"><h1 className="TitreAbout">QUI SOMMES-NOUS, DÉCOUVREZ COCOBINO</h1></div>
                            <div className="SsTitreAbout"><p>un voyage qui vous correspond</p></div>
                        </div>

                    </div>
                    <div className="BlocBasAbout">
                        <div className="BlocGaucheAbout">
                            <div className="BlocImages">
                                <div className="BlocImageHaut">
                                    <div className="BlocInterneImageGauche">
                                        <div className="blocImageABout1">
                                            <img src={natureLuxembourg} alt="" className="image1H"></img>
                                        </div>
                                        <div className="blocImageABout2"><img src={villeLuxembourgNuit} alt="" className="image1H"></img></div>
                                    </div>
                                    <div className="BlocInterneImageDroite">
                                        <div className="blocImageAbout3">
                                            <img src={mudamLuxembourg} alt="" className="imge1Droite"></img>
                                        </div>
                                    </div>

                                </div>
                                <div className="BlocImageBas">
                                    <div className="blocImageAbout4"><img src={img} alt="" className="image1Bas"></img></div>
                                </div>
                            </div>
                        </div>
                        <div className="BlocDroiteAbout">

                            <div className="blocStructureDroite">
                                <div className="bandeauAbout1">
                                    <div className="titreBandeau"><h2>Simplifier le tourisme local</h2></div>
                                </div>
                                <div className="bandeauAbout2">
                                    <div className="zoneTexteAbout">
                                        <p>
                                            Bienvenue chez Cocobino ! Notre vision audacieuse est de faciliter le tourisme au Luxembourg. Personnalisez votre voyage et vivez des week-ends de rêve, des moments de camping inoubliables. Contribuez à la culture locale en visitant le Luxembourg. Choisissez, réservez, profitez sans souci. 😄🌟 Réservez dès maintenant pour une expérience de voyage exceptionnelle ! #VisiterLeLuxembourg #WeekEndAuLuxembourg #CampingAuLuxembourg
                                        </p>
                                    </div>
                                </div>
                                <div className="bandeauAbout3">
                                    <div className="titreBandeau"><h2>Contribuer au tourisme locale</h2></div>
                                </div>
                                <div className="bandeauAbout4">
                                    <div className="zoneTexteAbout">
                                        <p>
                                            Chez Cocobino, nous avons à cœur de contribuer au tourisme local et culturel du Luxembourg. Nous travaillons exclusivement avec des hôtels, campings et activités locaux pour vous offrir une expérience authentique. Avec nous, visiter le Luxembourg est synonyme d'émerveillement et de joie profonde. Embarquez pour des week-ends magiques et des aventures en camping inoubliables. Réservez dès maintenant et vivez la grandeur du voyage responsable avec nous.
                                        </p>
                                    </div>
                                </div>
                                <div className="bandeauAbout5">
                                    <div className="titreBandeau"><h2>Un tourisme vert et digitale</h2></div>
                                </div>
                                <div className="bandeauAbout6">
                                    <div className="zoneTexteAbout">
                                        <p>
                                            Cocobino croit en un tourisme plus vert et responsable. Grâce à notre plateforme digitale, nous ouvrons les portes d'un voyage écoresponsable. Ensemble, créons des souvenirs durables tout en préservant notre planète. Rejoignez-nous pour une aventure consciente et inspirante. 💚✨
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="containerAbout">
                <div className="containerAbout-1">
                    <div className="blocHautP2">
                        <div className="TitreP2About"><h2>COCOBINO</h2></div>
                        <div className="DescP2About"><p>Frédérick Leshwange, co-fondateur (CEO), et Marie Brufina, co-fondatrice et (COO), passionnés de voyage et inspirés par les trésors du Luxembourg, ont uni leurs esprits créatifs pour créer Cocobino. Leur mission : faciliter le tourisme local et culturel. Une entreprise née de l'amour du voyage et de la créativité. Embarquez dans une aventure extraordinaire avec Cocobino ! ✈️🌍 </p></div>
                    </div>
                    <div className="blocBasP2">
                        <div className="TitreP2AboutBas"><h2>Prêt à voyager avec CocoBino</h2></div>
                        <div className="blocImageBasAboutP2">
                            <div className="encadreImageAbout">
                                <div className="fondImageAbout">
                                    <div className="bandeauHautBackground">
                                        <div className="bandeauHautBackground-1">Explorez nos packs de voyages</div>
                                    </div>
                                    <div className="bandeauBasBackground">
                                        <ButtonClassic
                                            name={'Explorez nos voyages'}
                                            number={2}
                                            newPage={"/boutique"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="encadreInfoAbout">
                                <div class="encadreInfoAbout-1">
                                    <div class="TB2">Nos packs de voyages?</div>
                                    <div class="TB2-1">
                                        <p>Vivez l'évasion absolue au Luxembourg avec Cocobino ! Découvrez nos packs de voyage sur mesure et créez votre week-end de rêve. Choisissez vos activités, réservez vos restaurants, et laissez-vous emporter par l'aventure sans souci. Des hôtels et campings de qualité vous attendent. Réservez dès maintenant et explorez le Luxembourg avec passion.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <Footer />
            </section>

        </div>

    );

};



export default About;