import React from 'react';
import '../styles/pages_style/HomePageThird.scss';
import img from '../styles/assets/images/image-chateau-de-clemency-chambre-home-page.webp';
import ButtonClassic from '../components/ButtonClassic';

const HomePageThird = () => {
    //hello
    return (
        <div>
            <section className="container3">
        <div className="container3-1">

            <div className="bloc3-1">

                <div className="titreC3-1"><h2>Ou aller en 2023 ?</h2></div>
                <div className="descriptionC3-1"><p>Découvrez notre nouvelle offre du moment</p></div>
            </div>


            <div className="bloc3-2">

                <div className="blocImage3-1">
                    <div className="imageBS1">
                                             
                        <div className="blocInfoImg">
                            <div className="infoImg">
                                <div className="imgTexte"><h3>Chateau de Clemency</h3></div>
                            </div>
                        </div>
                        <img src={img} alt="" id='image'/>
                    </div>
                </div>

                <div className="blocInformation">
                    <div className="blocTitreInfo">
                        <div className="titreInfo">Le repos au Rendez-vous</div>
                    </div>
                    <div className="blocInfoDesc">
                        <div className="descriptionInfo">
                            <p>
                            Envie de vous évader ! Le château de Clémency vous surprendra, avec ces 4 chambre complètement décorée, ce château vous offrira une expérience authentique et unique
                            </p>
                        </div>
                    </div>
                    <div className='blocEtoileAndButtonPrixContainer'>
                        <div className="blocEtoile">
                            <div className="etoileInfo"></div>
                            <div className="etoileInfo"></div>
                            <div className="etoileInfo"></div>
                        </div>
                        <div className="blocButtonPrix">
                            <ButtonClassic name={"Explorer"} number={1} newPage={"/voyages/Escapade_en_Amoureux"}/>
                            <div className="prixInfo">799€</div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </section>

    <section className="container4">
        <div className="container4-1">


            <div className="bloc4-1">

                <div className="titreC4-1">Nos avantages</div>
                <div className="descriptionC4-1"><p>Voyagez de la meilleure façon</p></div>
            </div>


            <div className="bloc4-2">
                <div className="bloc4-3">
                    <div className="bloc4-4">
                        <div className="bloc4-5">
                            <div className="bloc4-5Titre"><h3>Créez votre voyage sur mesure</h3></div>
                        </div>

                        <div className="bloc4-6">
                            <div className="bloc4-6Image1"></div>
                        </div>

                        <div className="bloc4-7">
                            <div className="bloc4-7Desc">
                                <p>
                                Plongez dans une expérience unique au Luxembourg ! Personnalisez votre aventure sur mesure : activités captivantes, délices culinaires, camping luxueux. Découvrez le Luxembourg comme jamais auparavant avec Cocobino. Réservez dès maintenant et réalisez votre rêve de visiter le Luxembourg.
                                </p>
                            </div>
                        </div>


                    </div>
                    <div className="bloc4-4">
                        <div className="bloc4-5">
                            <div className="bloc4-5Titre"><h3>Service client 7 jours sur 7</h3></div>
                        </div>

                        <div className="bloc4-6">
                            <div className="bloc4-6Image2"></div>
                        </div>

                        <div className="bloc4-7">
                            <div className="bloc4-7Desc">
                                <p>
                                Plongez dans une expérience inoubliable avec Cocobino. Notre service client exceptionnel vous accompagne tout au long de votre voyage au Luxembourg. Découvrez, visitez, campez - tout devient réalité ! 💫🌍🏕️ #VisiterLeLuxembourg #CampingLuxembourg #VoyageMagique
                                </p>
                            </div>
                        </div>


                    </div>
                    <div className="bloc4-4">
                        <div className="bloc4-5">
                            <div className="bloc4-5Titre"><h3>L'excellence luxembourgeoise avec Cocobino !</h3></div>
                        </div>

                        <div className="bloc4-6">
                            <div className="bloc4-6Image3"></div>
                        </div>

                        <div className="bloc4-7">
                            <div className="bloc4-7Desc">
                                <p>
                                
Plongez dans l'exceptionnel. Vivez des instants mémorables dans nos hôtels et campings de qualité. Explorez, ressentez, laissez-vous inspirer. Découvrez le Luxembourg avec passion. Réservez dès maintenant et ouvrez la porte vers des expériences uniques qui vous invitent à visiter le Luxembourg.
                                </p>
                            </div>
                        </div>


                    </div>

                </div>

            </div>

        </div>
    </section>

        </div>
    );
};

export default HomePageThird;