import React, { useState } from 'react';
import '../styles/pages_style/Boutique.scss';
import BoxDeVoyage from '../components/BoxDeVoyage';
import Footer from '../components/Footer';
import Header2 from "../components/Header2";
import { useNavigate } from 'react-router-dom';
import TravelPlanner from "../components/TravelPlanner";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import ScrollToTop from '../components/ScrollTop';

const Boutique = ({ setDateA, setDateD, checkOutDates, db, setHotel }) => {
    document.title = 'Découvrez les Meilleurs Hôtels Insolites au Luxembourg';

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        setOpen(true); // Ouvrir l'alerte
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false); // Fermer l'alerte
    };


    function handleClickNavigateForBoxDeVoyageVersTravelPage(hotel) {
        console.log(hotel);
        
        if (checkOutDates() === true) {
            localStorage.setItem("hotelFTP", JSON.stringify(hotel));
            setHotel(hotel);
            navigate(`/boutique/${hotel.title}/${hotel.id}`);
        } else {
            return handleClick();
        }
    }

    return (
        <div>

            <meta name="description" content="Découvrez les meilleures options d'hôtels insolites au Luxembourg avec Cocobino. Vivez une expérience unique et authentique dans ce joyau européen." />
            <ScrollToTop />
            <Header2 />
            <Snackbar
                open={open}
                autoHideDuration={4000}  // La durée avant que l'alerte ne disparaisse (en millisecondes)
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position de l'alerte
            >
                <Alert onClose={handleClose} severity="info">Veuillez mettre des dates</Alert>
            </Snackbar>
            <section className="container7">
                <div className="container7-1">
                    <div className="BackgroundPack">
                        <div className="backgroundDarken">
                            <div className="TitrePack"><h1>Découvrez les Meilleurs Hôtels Insolites au Luxembourg</h1></div>
                            <div className="DescriptionPack"><p>Explorez l'exception avec Cocobino : meilleurs hôtels insolites, voyages uniques au Luxembourg. Vivez une expérience inoubliable dans ce joyau européen. Le Luxembourg à tellement de choses à offrir et notre mission, c'est de vous les faire découvrir !</p></div>

                        </div>
                    </div>
                </div>
            </section>
            {<TravelPlanner setDateA={setDateA} setDateD={setDateD} />}

            <section className='sectionBoxDeVoyage'>
                <div className='sectionBoxDeVoyage__PageViewContainer'>
                    {db.hotels.map((hotel_db, key) => {
                        return (
                            <BoxDeVoyage
                                hotel={hotel_db}
                                handleClickNavigate={handleClickNavigateForBoxDeVoyageVersTravelPage}
                                key={key}
                            />
                        );
                    })}
                </div>
            </section>

            <Footer />
        </div>
    );
};

/**
 * 
 *  On implementera ceci plus tard 
 * 
 *                          <div class="BlocBasPack">
                                <div class="listeCenter">
                                    <div class="blocB1">
                                        <button class="bouton1">Populaire</button>
                                    </div>
                                    <div class="blocB2">
                                        <button class="bouton2">Récent</button>
                                    </div>
                                    <select class="liste" name="" id="">
                                        <option value="aventure">Aventure</option>
                                        <option value="family">Family</option>
                                        <option value="coco">Coco</option>
                                    </select>
                                </div>
                            </div>
 */

export default Boutique;