import React from 'react';
import '../../styles/components_style/adventageBubble.scss';

const AdventageBubble = ({adventageList}) => {
    return (
        <>
            <div className='advantageContainer'>
                <div className='advantageContainer_topSetting'>
                    {adventageList.map((string, key) => {
                        return (
                            <div className='advantageContainer_elmnt' key={key}>
                                <p className='advantageContainer_elmnt_p' key={key}>{string}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    );
};

export default AdventageBubble;