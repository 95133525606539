import React, { useRef, useState } from 'react';
import '../styles/pages_style/Contact.scss';
import Footer from "../components/Footer";
import ButtonClassic from '../components/ButtonClassic';
import Header2 from "../components/Header2";
import emailjs from '@emailjs/browser';
import Alert from '../components/Alert';
import AlertError from '../components/AlertError';
import ScrollToTop from "../components/ScrollTop";

const Contact = () => {
    document.title = 'Contactez cocobino - entrez en contact avec notre équipe';
    const [valueAlert, setValueAlert] = useState(false);
    const [valueAlertFalse, setValueAlertFalse] = useState(false);

    const [nom, setNom] = useState('');
    const [nomFam, setNomFam] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const form = useRef();

    function formValidator(){
        let result = false;
        if(nom.length !== 0 && nomFam.length !== 0 && email.length !== 0 && message.length !== 0 && email.indexOf('@')!==-1 && email.indexOf('.')!==-1){
            result = true;
        }
        return result;
    }

    const sendEmail = (e) => {
      e.preventDefault();

      if(formValidator()!==false){
        emailjs.sendForm('service_myw83ok', 'template_siywei7', form.current, '4dxMbBAe-jbiAzBod')
        .then((result) => {
            console.log(result.text);
            console.log('message envoyé');
            setValueAlert(true); 
            setTimeout(() => {
                setValueAlert(false);
            }, 4000);  
        }, (error) => {
            console.log(error.text);
        });
      } else {
        setValueAlertFalse(true); 
        setTimeout(() => {
            setValueAlertFalse(false);
        }, 4000); 
      }
    };  

    return (
        <div>
            {valueAlert && (
                <Alert/>
            )}
            {valueAlertFalse && (
                <AlertError />
            )}
            <Header2 />
            <ScrollToTop />
            <div className="containerContact">
        <div className="containerContact-1">
            <div className="ContactBlocGauche">
                <div className="ContactVide"></div>
                    <div className="ContactBlocHaut">
                        <div className="ContactTitre1">
                            <div className="TitreHaut"><h1>Contactez-nous</h1></div>
                        </div>
                        <div className="ContactSousTitre1">
                            <div className="SsTitreHaut"><p>Restons en contact</p></div>
                        </div>
                    </div>

                <form ref={form} className="ContactBlocFormulaire" onSubmit={sendEmail}>
                    <div className="ContactBlocMilieu">
                        <div className="ContactMilieuGauche">

                            <div className="BMG">
                                
                                <div className="blocName">
                                    <p className='p-error'>* champ obligatoire</p>
                                    <input className="BN" type="texte" placeholder="Prénom" name="user_nom" onChange={(e)=>setNom(e.target.value)}></input>
                                </div>
                                <div className="blocName">
                                    <p className='p-error'>* champ obligatoire</p>
                                    <input className="BN" type="texte" placeholder="Nom" name="user_prenom" onChange={(e)=>setNomFam(e.target.value)}></input>
                                    </div>
                                <div className="blocName">
                                    <input className="BN" type="number" placeholder="+352 Tel" name="user_phone"></input>
                                </div>
                                <div className="blocName">
                                    <p className='p-error'>* champ obligatoire</p>
                                    <input className="BN" type="email" placeholder="Email@" name="user_email" onChange={(e)=>setEmail(e.target.value)}></input>
                                </div>
                            </div>

                        </div>
                        <div className="ContactMilieuDroite">
                            <div className="BMD">
                                <div className="ContactBlocMessage">
                                    <p className='p-error'>* champ obligatoire</p>
                                    <input className="BMess" type="textarea" placeholder="Votre Message" name="user_message" onChange={(e)=>setMessage(e.target.value)}></input>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="ContactBlocBas">
                        <div className="ContactBasBlocBouton">
                            <input type="submit" value="Envoyer" className='boutonEnvoyer'onClick={sendEmail}/>
                        </div>
                    </div>
                </form>
            </div>
            <div className="ContactBlocDroite">

                <div className="ContactBlocCentraleDroite">
                    <div className="ContactBackground">
                        <div className="ContactBandeauBackground">
                            <div className="ContactTitre"><h2>Qui sommes nous! Cocobino</h2></div>
                        </div>
                        <div className="ContactBandeauBackground">
                            <div className="ContactDescription"><p>Expérience de voyage exceptionnelle avec Cocobino : découvertes, détente et souvenirs inoubliables.</p></div>
                        </div>
                        <div className="ContactBandeauBackground"> 
                            <ButtonClassic 
                                name={'En savoir plus'}
                                number={2}
                                newPage={"/about"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </div>
    );

};



export default Contact;