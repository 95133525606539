import React from 'react';
import img1 from '../../../styles/assets/images/articles/cossy cabanes/cossy1.jpg';
import img2 from '../../../styles/assets/images/articles/cossy cabanes/cossy2.jpg';
import img3 from '../../../styles/assets/images/articles/cossy cabanes/cossy3.jpg';
import img4 from '../../../styles/assets/images/articles/cossy cabanes/cossy4.jpg';
import img5 from '../../../styles/assets/images/articles/Interieur de la cabane Nutchel en Belgique.jpg';
import img6 from '../../../styles/assets/images/articles/image-vue-interieurde-la-cabane-Arctic-tree-house-hotel-en-finlande.jpg';
import PageVenteTemplate from './PageVenteTemplate';

const PageVenteArticleBelgique = () => {

    return (
        <PageVenteTemplate 
            im1={img1}  
            im2={img2}
            im3={img3}
            im4={img4}
            im5={img5}
            im6={img6}
            title={'Belgique'}
        />
    );
};

export default PageVenteArticleBelgique;