import React from 'react';
import img1 from '../../../styles/assets/images/articles/image de la cabane sur eau de neuchatel.jpg';
import img2 from '../../../styles/assets/images/articles/swiss/swiss1.jpg';
import img3 from '../../../styles/assets/images/articles/swiss/swiss2.jpg';
import img4 from '../../../styles/assets/images/articles/swiss/swiss3.jpg';
import img5 from '../../../styles/assets/images/articles/swiss/swiss4.jpg';
import img6 from '../../../styles/assets/images/articles/swiss/swiss5.jpg';
import PageVenteTemplate from './PageVenteTemplate';

const PageVenteArticleSuisse = () => {
    return (
        <PageVenteTemplate 
            im1={img1}  
            im2={img2}
            im3={img3}
            im4={img4}
            im5={img5}
            im6={img6}
        />
    );
};

export default PageVenteArticleSuisse;